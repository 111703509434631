@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --bg: #050D19;
    --white: #fff;
    --white-rgb: 255, 255, 255;
    --black: #050D19;
    --lime: #9EFF00;
    --tag-text: #01C9FF;
    --tag-bg: 1, 201, 255;
    --button-txt: #1D2939;

    --dim: #FFF5F5;
    --dim-rgb: 255, 245, 245;
  }
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  scroll-behavior: smooth;
  scrollbar-width: auto;
}

body {
  background: var(--bg);
  color: var(--white);
  overflow-x: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: screen;
}

svg,
img {
  max-width: 100%;
  max-height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: var(--bg);
  border-radius: 8px;
  box-shadow: inset 2px 2px 2px var(--button-txt), inset -2px -2px 2px var(--button-txt);
}

::-webkit-scrollbar-track {
  background: linear-gradient(90deg,
      var(--bg),
      var(--bg) 1px,
      var(--bg) 0,
      var(--bg));
}

.profile {
  border-radius: 100px;
  border: 2px solid rgba(var(--white-rgb), 0.04);
  background: var(--bg);
  line-height: 0;
}

.navbar-nav {
  backdrop-filter: blur(10px);
}

nav ul {
  opacity: .5;
}

.dropdown-menu {
  border-radius: 32px;
  border: 1.5px solid rgba(255, 245, 245, 0.06);
  background: rgba(5, 5, 5, 0.50);
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(40px);
  margin-left: 0 !important;
}

footer .wrapper::before,
footer .wrapper::after {
  content: '';
  position: absolute;
  width: 100%;
  z-index: -1;
}

footer .wrapper::before {
  top: 0;
  height: 100%;
  background: linear-gradient(180deg, rgba(5, 13, 25, 0.00) -38.23%, #050D19 105.81%);
}

footer .wrapper::after {
  top: 200px;
  height: 1270px;
  border-radius: 1645px;
  background: linear-gradient(180deg, #014CFF 14.62%, #9EFF00 100%);
  filter: blur(100px);
}

footer .footer-top::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: url(https://cdn.maians.io/maians/footer-top-pattern.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

footer .footer-bottom::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 20px;
  background-image: url(https://cdn.maians.io/maians/footer-center-pattern.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

footer .footer-bottom::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://cdn.maians.io/maians/footer-maians-pattern.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

@media (min-width: 990px) {
  footer .wrapper::after {
    top: 300px;
  }

  footer .footer-top::before,
  footer .footer-bottom::before {
    height: 50px;
  }

  footer .footer-bottom::before {
    top: 10px;
  }
}

@layer components {
  
}

button {
  transition: .35s all;
}

.primary-button {
  background: var(--lime);
  color: var(--black);
  border: none;
}

.primary-button svg path {
  fill: var(--black) !important;
  stroke: var(--black) !important;
}

input {
  width: 100%;
  color: var(--white);
  background: transparent;
  outline: none;
  box-shadow: none;
  padding: 10px 15px;
}

input:focus {
  border-color: var(--lime);
  box-shadow: 0px 0px 16px 2px rgba(158, 255, 0, 0.50) inset;
  outline: none !important;
}

@media (min-width: 1050px) {
  .nav-link:hover svg path {
    fill: var(--lime);
  }

  .primary-button:hover svg path {
    fill: var(--lime) !important;
    stroke: var(--lime) !important;
  }

  .primary-button:hover {
    border-color: var(--lime);
    color: var(--lime);
    background: rgba(158, 255, 0, 0.14);
    box-shadow: 0px 0px 16px 2px rgba(158, 255, 0, 0.50) inset;
  }

  .primary-button.glow:hover {
    border-color: var(--lime);
    color: var(--black);
    background: var(--lime);
  }

  .primary-button.glow:hover svg path {
    fill: var(--black) !important;
    stroke: var(--black) !important;
  }

  .outline-button:hover {
    border-color: var(--lime);
    background: var(--lime);
    color: var(--black);
  }

  .outline-button:hover svg path {
    fill: var(--black);
    stroke: var(--black);
  }

  .twitter:hover svg path {
    stroke: none;
  }

}