.blur-image-effect::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://cdn.maians.io/maians/blur-img-effect.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-filter: blur(100px);
  filter: blur(100px);
  z-index: -1;
}

.heroSection.blur-image-effect::after {
  right: 0;
  left: auto;
}

.hero-bgImg {
  background-image: url(https://cdn.maians.io/maians/bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.builtForScale::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0px;
  width: 20%;
  height: 20%;
  background-image: url(https://cdn.maians.io/maians/built-for-scale-pattern.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.builtForScale .video-holder::before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  border-radius: 16px;
  background: rgba(217, 217, 217, 0.04);
  backdrop-filter: blur(5px);
}

.builtForScale .built-bg-effect::after {
  content: '';
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-radius: 1796px;
  background: linear-gradient(180deg, #014CFF 14.62%, #9EFF00 100%);
  filter: blur(100px);
  width: 80%;
  height: 50%;
  z-index: 1;
}

.aiPowered {
  width: 100%;
  background-image: url(https://cdn.maians.io/maians/aipowered-bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.aiPowered::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(0deg, #050D19 31.93%, rgba(5, 13, 25, 0.00) 93%);
}

.ai-img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(217, 217, 217, 0.10) 0%, rgba(255, 255, 255, 0.00) 84.87%);
  backdrop-filter: blur(5px);
  width: 100%;
  height: 100%;
  z-index: -1;
}

.marketplace::after {
  content: '';
  position: absolute;
  top: 10vh;
  left: 0;
  width: 20px;
  height: 60vh;
  background-image: url(https://cdn.maians.io/maians/martket-left-pattern.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.marketplace .after-effect {
  top: -5px;
  left: -5px;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
}

.brandEmpower::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.03;
  background: url(https://cdn.maians.io/maians/empower-bg-grains.webp), lightgray -664.198px -323.069px / 159.192% 155.926% no-repeat;
  z-index: -1;
}

.brandEmpower .effects::before {
  content: '';
  position: absolute;
  top: -3rem;
  left: 0;
  width: 100%;
  height: 20%;
  transform: rotate(180deg);
  background: linear-gradient(0deg, #050D19 31.93%, rgba(5, 13, 25, 0.00) 93%);
}

.brandEmpower .effects::after {
  content: '';
  position: absolute;
  bottom: 0%;
  left: 0;
  width: 100%;
  height: 20%;
  background: linear-gradient(0deg, #050D19 31.93%, rgba(5, 13, 25, 0.00) 93%);
}

.brandEmpower__bottom::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://cdn.maians.io/maians/brand-bg.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(40px);
  z-index: 1;
}

.brandEmpower__bottom::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 75%;
  height: 100%;
  border-radius: 2159px;
  background: linear-gradient(180deg, rgba(1, 76, 255, 0.40) 14.62%, rgba(158, 255, 0, 0.40) 100%);
  filter: blur(120px);
}

.brandEmpower__name {
  background: url(https://cdn.maians.io/maians/brand-text-bg.webp);
  background-repeat: no-repeat;
  background-size: 100%;
}

.brandEmpower__name .name::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://cdn.maians.io/maians/empowerment-pattern.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.slide-up {
  -webkit-animation: slide-up 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
  animation: slide-up 5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite both;
  border-radius: 0px 0px 35px 36px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 92.42%);
  backdrop-filter: blur(5px);
}

.slide-up::before,
.slide-up::after {
  content: '';
  position: absolute;
  top: 0;
  width: 50.1%;
  height: 3px;
  z-index: 1;
}

.slide-up::before {
  left: 0;
  transform: rotate(180deg);
  background: linear-gradient(90deg, #9EFF00 50%, rgba(255, 255, 255, 0.00) 100%);
}

.slide-up::after {
  right: 0;
  background: linear-gradient(90deg, #9EFF00 50%, rgba(255, 255, 255, 0.00) 100%);
}

@-webkit-keyframes slide-up {
  0% {
    height: 10%;
  }

  50% {
    height: 90%;
  }

  100% {
    height: 10%;
  }
}

@keyframes slide-up {
  0% {
    height: 10%;
  }

  50% {
    height: 90%;
  }

  100% {
    height: 10%;
  }
}


.partnerCard {
  border: 1.5px solid rgba(255, 255, 255, 0.04);
  background: rgba(217, 217, 217, 0.04);
  backdrop-filter: blur(10px);
  isolation: isolate;
  transition: .25s ease-in-out;
  overflow: hidden;
  z-index: 1;
}

@media (min-width: 1500px) {
  .builtForScale .built-bg-effect::after {
    width: 779px;
    height: 1500px;
  }

  .builtForScale::before {
    top: -50px;
    right: -80px;
  }

  .builtForScale .video-holder::before {
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    border-radius: 32px;
  }

  .aiPowered::before {
    height: 35px;
  }

  .marketplace::after {
    top: 20vh;
    left: 0;
    width: 46px;
    height: 80vh;
  }

  .brandEmpower .effects::before {
    top: -4rem;
  }

  .aiPowered::after {
    height: 300px;
  }
}

@media (min-width: 990px) {
  .aiPowered::before {
    height: 18px;
  }

  .partnerCard:hover {
    background-image: url(https://cdn.maians.io/maians/partner-noise.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .partnerCard:hover::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-image: url(https://cdn.maians.io/maians/partner-hover-bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    filter: blur(40px);
    -webkit-filter: blur(40px);
    z-index: -1;
  }

  .marketplace .after-effect {
    top: -27px;
    left: -27px;
    width: calc(100% + 56px);
    height: calc(100% + 56px);
  }
}

@media (max-width: 1499px) {
  .heroSection .wrap::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    z-index: 12;
  }
}